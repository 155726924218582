import React, { useMemo, useState, useEffect } from "react";
import { useQuery } from "react-query";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader, Table } from "../../../Components";
import { TableColumns } from "./WinnerCol";
import { campaignClient } from "../../../api";
import { toast } from "../../../Components/Toast/ToastManager";
import { decrypt, styles } from "../../../helpers/hooks";
import { useForm } from "react-hook-form";
import { useStores } from "../../../stores";
import dayjs from "dayjs";

type RegisterFormData = {
  winnerMonth: string;
};

function WinnerSelection() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<RegisterFormData>({ mode: "all" });
  const [modal, setModal] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [sessionKey, setSessionKey] = useState<string>("");
  const [winnerMonth, setWinnerMonth] = useState<string>("");
  const [mosaicEmail, setMosaicEmail] = useState<string>();
  const [filterTable, setFilterTable] = useState("");


  const user = useStores().authStore.getUser().name;

  const {
    isLoading: isLoadingparticipants,
    data: participants,
    refetch,
  } = useQuery(["getParticipants", {}], async () => {
    const res: MainDbReturnTypes.Participants = await campaignClient.call(
      "getParticipants",
      {}
    );
    const filtered = res.participants.filter(a => a.metadata.winnerMonth).sort((a, b) => a.metadata.winnerMonth.split('-')[1] - b.metadata.winnerMonth.split('-')[1])
    return [...filtered, ...res.participants.filter(a => !a.metadata.winnerMonth)]
  });

  const handleSelectWinner = async (data: RegisterFormData) => {
    if (submitting) {
      return;
    }

    setSubmitting(true);

    try {
      let { sendEmailResult, error, message }: any = await campaignClient.call("selectWinner", {
        sessionKey: sessionKey,
        winnerMonth: data.winnerMonth,
        wilyEmail: user,
      });
      if (error) {
        toast.show({
          content: `${message}`,
          duration: 3000,
          error: true,
        });
      }
      else {
        toast.show({
          content: `Winner has been selected.`,
          duration: 3000,
          error: false,
        });
        refetch();
      }


      setModal(false);
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);

      toast.show({
        content: `Winner confirmation is failed.`,
        duration: 3000,
        error: true,
      });

      console.log(e);
      throw e;
    }
  };

  const handleResetWinner = async () => {
    if (submitting) {
      return;
    }

    setSubmitting(true);

    try {
      let { sendEmailResult }: any = await campaignClient.call("resendClaim", {
        sessionKey: sessionKey,
        winnerMonth: winnerMonth,
      });

      await campaignClient.call("sendEmailWily", {
        placeHolder: "winnerReset",
        subjectLine: "Winner Reset",
      });

      setResetModal(false);
      setSubmitting(false);

      toast.show({
        content: `Winner has been reset.`,
        duration: 3000,
        error: false,
      });
      refetch();
    } catch (e) {
      setSubmitting(false);

      toast.show({
        content: `Winner reset is failed.`,
        duration: 3000,
        error: true,
      });

      console.log(e);
      throw e;
    }
  };
  const data = useMemo(() => {
    if (participants && participants.length > 0) {
      return participants.map((participant) => {

        const daysleft = participant.metadata?.emailExpiry ? dayjs(participant.metadata?.emailExpiry.split(",")[0]).diff(dayjs(), 'days') + 1 : null
        return {
          sessionKey: participant.sessionKey,
          drawMonth: participant.metadata.drawMonth,
          winnerMonth: participant.metadata.winnerMonth,
          uuid: participant.metadata.uniqid,
          firstName: participant.firstName,
          lastName: participant.lastName,
          email: participant.email,
          status: daysleft === null ? "N/A" :
            participant.metadata.claimed
              ? "Claimed"
              : daysleft > 0 ? "Pending" : "Expired",
          emailExpiry: participant.metadata.emailExpiry,
        };
      })
    }
  }, [participants, winnerMonth]);

  const functions = {
    setModal,
    setResetModal,
    setSessionKey,
    setWinnerMonth,
  };

  const columns = TableColumns(functions);

  if (isLoadingparticipants || submitting) {
    return <PageLoader />;
  }

  return (
    !isLoadingparticipants && (
      <>
        <div className="main__head">
          <h2 className="main__title">Contest winners</h2>
        </div>
        <div className="main__body">
          <div className="tabs js-tabs">
            <div className="tabs__head">
              <nav className="tabs__nav">
                <ul></ul>
              </nav>
            </div>

            {data && data.length > 0 ? (
              <div className="tabs__body mt-5">
                <div className="table table--alt table--tabs table--big">
                  <Table
                    columns={columns}
                    data={data ? data : []}
                    tablePageSize={15}
                    filterValue={filterTable}
                  />
                </div>
              </div>
            ) : (
              <p>Currently there are no contest participants to display.</p>
            )}
          </div>
        </div>

        {modal && (
          <div
            className="modal fade warning"
            id="Modal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={styles.modal}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                {" "}
                <div
                  className="modal-header"
                  style={{ flexDirection: "row-reverse" }}
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    style={{ flexDirection: "row-reverse", border: "none" }}
                    onClick={() => setModal(false)}
                  >
                    <span
                      aria-hidden="true"
                      style={{ fontWeight: "bold", backgroundColor: "#fff" }}
                    >
                      X
                    </span>
                  </button>
                </div>
                <div className="modal-body">
                  <h3
                    style={{
                      padding: "10px 10px 10px 0px",
                      marginRight: "5rem",
                    }}
                  >
                    Select winner
                  </h3>
                  <h5 style={{ textAlign: "left", width: "75%" }}>
                    Please select the month this winner will win for and click
                    confirm
                  </h5>{" "}
                  <br />
                  <form onSubmit={handleSubmit(handleSelectWinner)}>
                    <div className="formContent">
                      <div className="form-group">
                        <label className="form__label">Winner month</label>
                        <br />
                        <select
                          className="form-control-select"
                          style={{ width: "50%" }}
                          {...register("winnerMonth", {
                            required: {
                              value: true,
                              message: "Please select a winner month.",
                            },
                          })}
                        >
                          <option value="">Select</option>
                          <option value={`2024-02`}>
                            February 2024
                          </option>
                          <option value={`2024-03`}>
                            March 2024
                          </option>
                          <option value={`2024-04`}>
                            April 2024
                          </option>
                          <option value={`2024-05`}>
                            May 2024
                          </option>
                          <option value={`2024-06`}>
                            June 2024
                          </option>
                          <option value={`2024-07`}>
                            July 2024
                          </option>
                          <option value={`2024-08`}>
                            August 2024
                          </option>
                          <option value={`2024-09`}>
                            September 2024
                          </option>
                          <option value={`2024-10`}>
                            October 2024
                          </option>
                          <option value={`2024-11`}>
                            November 2024
                          </option>
                          <option value={`2024-12`}>
                            December 2024
                          </option>
                          <option value={`2024-01`}>
                            January 2025
                          </option>
                        </select>
                        {errors.winnerMonth && (
                          <p className="error" style={{ width: "50%" }}>
                            <i className="fas fa-exclamation-circle" />{" "}
                            {errors.winnerMonth.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <br />

                    <div className="main__body main__body--flex">
                      <div
                        className="form__footer"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <button
                          type="button"
                          className="btn"
                          data-dismiss="modal"
                          style={{ backgroundColor: "" }}
                          onClick={() => {
                            setModal(false);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn"
                          disabled={submitting}
                          style={{ marginLeft: "15px" }}
                        >
                          {submitting ? "...Confirming" : "Confirm"}
                        </button>
                      </div>
                    </div>
                  </form>
                  <br />
                </div>
              </div>
            </div>
          </div>
        )}

        {resetModal && (
          <div
            className="modal fade warning"
            id="Modal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={styles.modal}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                {" "}
                <div
                  className="modal-header"
                  style={{ flexDirection: "row-reverse" }}
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    style={{ flexDirection: "row-reverse", border: "none" }}
                    onClick={() => setResetModal(false)}
                  >
                    <span
                      aria-hidden="true"
                      style={{ fontWeight: "bold", backgroundColor: "#fff" }}
                    >
                      X
                    </span>
                  </button>
                </div>
                <div className="modal-body">
                  <h3
                    style={{
                      padding: "10px 10px 10px 0px",
                      marginRight: "5rem",
                    }}
                  >
                    Resend prize claim email ?
                  </h3>
                  <h5 style={{ textAlign: "left", width: "75%" }}>
                    Are you sure you would like to resend the prize claim link
                    for the prize winner?
                  </h5>{" "}
                  <br />
                  <div className="main__body main__body--flex">
                    <div
                      className="form__footer"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <button
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                        style={{ backgroundColor: "" }}
                        onClick={() => {
                          setResetModal(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn"
                        onClick={() => handleResetWinner()}
                        disabled={submitting}
                        style={{ marginLeft: "15px" }}
                      >
                        {submitting ? "...Resending" : "Resend"}
                      </button>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  );
}

export default WinnerSelection;
