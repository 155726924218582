import { TableProps } from "react-table";

export const TableColumns = (fns: {
  setEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  setUuid: React.Dispatch<React.SetStateAction<string>>;
  setDrawMonth: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const COLUMNS = [
    {
      Header: "Draw month",
      accessor: "drawMonth",
      width: "5.1%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].drawMonth
          ? TableInfo.data[TableInfo.row.index].drawMonth
          : "N/A";
      },
    },
    {
      Header: "Winner month",
      accessor: "winnerMonth",
      width: "5.1%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].winnerMonth
          ? TableInfo.data[TableInfo.row.index].winnerMonth
          : "N/A";
      },
    },
    {
      Header: "UUID",
      accessor: "uuid",
      width: "5.1%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].uuid
          ? TableInfo.data[TableInfo.row.index].uuid
          : "N/A";
      },
    },
    {
      Header: "First name",
      accessor: "firstName",
      width: "7.7%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].firstName
          ? TableInfo.data[TableInfo.row.index].firstName
          : "";
      },
    },
    {
      Header: "Last name",
      accessor: "lastName",
      width: "7.7%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].lastName
          ? TableInfo.data[TableInfo.row.index].lastName
          : "";
      },
    },
    {
      Header: "Phone number",
      accessor: "phone",
      width: "13.1%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].phone
          ? TableInfo.data[TableInfo.row.index].phone
          : "";
      },
    },
    {
      Header: "Email",
      accessor: "email",
      width: "13.1%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].email
          ? TableInfo.data[TableInfo.row.index].email
          : "";
      },
    },
    {
      Header: "#",
      accessor: "test",
      Cell: (TableInfo: TableProps) => {
        const status = TableInfo.data[TableInfo.row.index].status
        if(status){
          return <p> Winner </p>
        }
        else{
          return (
            <a
              style={{ textDecoration: "none", color: "blue" }}
              onClick={() => {
                fns.setUuid(TableInfo.data[TableInfo.row.index].uuid);
                fns.setDrawMonth(TableInfo.data[TableInfo.row.index].drawMonth);
                fns.setEditModal(true);
              }}
            >
              Edit
            </a>
          );
        }
       
      },
    },
  ];

  return COLUMNS;
};
