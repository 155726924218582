import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { CSVLink } from "react-csv";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader, Table } from "../../../Components";
import { TableColumns } from "./WinnerCol";
import { campaignClient } from "../../../api";

function ViewWinners() {
  const { isLoading: isLoadingparticipants, data: prizeWinners } = useQuery(
    ["getPrizeWinners", {}],
    async () => {
      const res: MainDbReturnTypes.PrizeWinners = await campaignClient.call("getPrizeWinners", {});
      return res.prizeWinners;
    }
  );

  const [filterTable, setFilterTable] = useState("");

  function capitalizeFirstLetter(data: string) {
    return data?.charAt(0).toUpperCase() + data?.slice(1);
  }

  const data = useMemo(() => {
    if (prizeWinners && prizeWinners.length > 0) {
      return prizeWinners
        .filter((pw) => pw.declarationAndRelease)
        .map((rp) => {
          const participant = rp.participant;
          return {
            sessionKey: participant.sessionKey,
            drawMonth: participant.metadata.drawMonth,
            winnerMonth: participant.metadata.winnerMonth,
            fulfillTime: rp.declarationAndRelease
              ? new Date(rp.declarationAndRelease.creationTime).toLocaleString()
              : "NA",
            firstName: capitalizeFirstLetter(participant?.firstName!),
            lastName: capitalizeFirstLetter(participant?.lastName!),
            email: participant.email,
          };
        });
    }
  }, [prizeWinners]);

  const reportData = useMemo(() => {
    if (data) {
      return data.map((rp: any) => {
        return {
          "Winner Month": rp.winnerMonth,
          "Claim date/ time": rp.fulfillTime.toLocaleString(),
          "First name": rp.firstName,
          "Last name": rp.lastName,
          Email: rp.email,
        };
      });
    }
  }, [data]);

  const columns = TableColumns();

  if (isLoadingparticipants) {
    return <PageLoader />;
  }

  return (
    !isLoadingparticipants && (
      <>
        <div className="main__head">
          <h2 className="main__title">Contest winners</h2>

          <div className="main__actions">
            <div className="search search--alt">
              <div className="search__row search__row--input">
                <CSVLink
                  filename={`Campaign-Winner-Report-${new Date().toLocaleDateString()}`}
                  className="btn btn--medium btn--mobile-small"
                  data={reportData ? reportData : ""}
                  asyncOnClick={true}
                  target="_blank">
                  Export winners
                </CSVLink>
              </div>
            </div>
          </div>
        </div>
        <div className="main__body">
          <div className="tabs js-tabs">
            <div className="tabs__head">
              <nav className="tabs__nav">
                <ul></ul>
              </nav>
            </div>
            {data && data.length > 0 ? (
              <div className="tabs__body mt-5">
                <div className="table table--alt table--tabs table--big">
                  <Table
                    columns={columns}
                    data={data ? data : []}
                    tablePageSize={15}
                    filterValue={filterTable}
                  />
                </div>
              </div>
            ) : (
              <p>Currently there are no prize claimed contest winners to display.</p>
            )}
          </div>
        </div>
      </>
    )
  );
}

export default ViewWinners;
