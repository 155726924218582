import axios from "axios";
import _ from "lodash";
import { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { campaignClient } from "../../../api";
import { PageLoader } from "../../../Components";
import CsvReader from "../../../Components/CSVReader";
import { toast } from "../../../Components/Toast/ToastManager";
import { decrypt, styles } from "../../../helpers/hooks";

type FormData = {
  drawMonth: string;
  file: File;
};

type csvData = {
  uuid: string;
};

const sampleFile: csvData[] = [
  {
    uuid: "1234567890123456789012345678901",
  },
];

const headers = [{ label: "UUID", key: "uuid" }];

function UploadUuids() {
  const navigate = useNavigate();
  const [data, setData] = useState<any>();
  const [drawMonth, setDrawMonth] = useState<string>();
  const [file, setFile] = useState<any>();
  const [uploading, setUploading] = useState(false);
  const [uploadingPercent, setUploadingPercent] = useState(0);
  const [uploadError, setUploadError] = useState(false);
  const [duplicateFile, setDuplicateFile] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [modal, setModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ mode: "all" });

  const onSubmit = handleSubmit((data: FormData) => {
    // handle form submission here
    setModal(true);
    setData(file.data);
    // setFile(file.file);
    setDrawMonth(data.drawMonth);
  });

  const { isLoading: isLoadingDraws, data: draws } = useQuery(
    "getDrawMonths",
    async () => {
      const res: any = await campaignClient.call("getDrawMonths", {});
      const destructuredDrawsData: any = _.map(res, (obj) => {
        return _.mapValues(obj, (val) => {
          return val[Object.keys(val)[0]];
        });
      });
      return destructuredDrawsData;
    }
  );



  async function handleUpload() {
    if (!file || uploading) {
      return;
    }
    const fileToUpload = file.file;
    setUploadError(false);
    setUploading(true);
    setUploadingPercent(0);
    setDuplicateFile(false);
    setUploadComplete(false);
    try {
      const { key, uploadUrl }: any = await campaignClient.call("uploadUuid", {
        filename: fileToUpload.name,
        drawMonth: drawMonth,
        contentType: fileToUpload.type,
      });

      const csv = await fileToUpload.text();
      let lines = csv.split(/\r\n|\n/);
      lines.shift();
      const data = lines.join("\n");

      await axios.put(uploadUrl, data, {
        headers: { "Content-Type": fileToUpload.type },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadingPercent(percentCompleted);
          }

        },
      });
      setUploadComplete(true);

      await campaignClient.call("sendEmailMosaic", {
        placeHolder: "confIDs",
        subjectLine: "File Submitted",
      });

      await campaignClient.call("sendEmailWily", {
        placeHolder: "confIDs",
        subjectLine: "Action Required",
        btn: true,
      });

      toast.show({
        content: `File submitted.`,
        duration: 3000,
        error: false,
      });

      navigate("/draws");
    } catch {
      setUploadError(true);

      toast.show({
        content: `File submission failed.`,
        duration: 3000,
        error: true,
      });
    }
    setUploading(false);
  }

  if (uploading || submitting || isLoadingDraws) {
    return <PageLoader />;
  }

  const validDraw = (mon: string) => {
    return (
      draws &&
      draws.filter((draw: any) => draw.sk === mon && draw.prizePoolUploadTime)
    );
  };

  return (
    draws && (
      <>
        <div className="main__head">
          <h2 className="main__title">Upload UUIDS</h2> <br />
          <h4 style={{ maxWidth: "55%", textAlign: "left" }}>
            To upload a file containing user UUIDS, select the month for the
            draw then select the file from your computer and drag and drop it
            into the designated area onscreen. Once your file is uploaded,
            please ensure the file is correct before clicking the 'submit'
            button below.
          </h4>
        </div>{" "}
        <form onSubmit={onSubmit}>
          <div className="main__body main__body--flex main__body--flex-alt">
            <div className="boxes-info">
              <ul
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <li>
                  <h5>Draw month</h5>
                  <select
                    className="form-control-select"
                    {...register("drawMonth", { required: true })}
                  >
                    <option value="">Select</option>
                    <option value={`2024-02`}
                      disabled={validDraw("2024-02").length > 0 ? true : false}
                    >
                      February 2024
                    </option>
                    <option
                      value={`2024-03`}
                      disabled={validDraw("2024-03").length > 0 ? true : false}
                    >
                      March 2024
                    </option>
                    <option
                      value={`2024-04`}
                      disabled={validDraw("2024-04").length > 0 ? true : false}
                    >
                      April 2024
                    </option>
                    <option
                      value={`2024-05`}
                      disabled={validDraw("2024-05").length > 0 ? true : false}
                    >
                      May 2024
                    </option>
                    <option
                      value={`2024-06`}
                      disabled={validDraw("2024-06").length > 0 ? true : false}
                    >
                      June 2024
                    </option>
                    <option
                      value={`2024-07`}
                      disabled={validDraw("2024-07").length > 0 ? true : false}
                    >
                      July 2024
                    </option>
                    <option
                      value={`2024-08`}
                      disabled={validDraw("2024-08").length > 0 ? true : false}
                    >
                      August 2024
                    </option>
                    <option
                      value={`2024-09`}
                      disabled={validDraw("2024-09").length > 0 ? true : false}
                    >
                      September 2024
                    </option>
                    <option
                      value={`2024-10`}
                      disabled={validDraw("2024-10").length > 0 ? true : false}
                    >
                      October 2024
                    </option>
                    <option
                      value={`2024-11`}
                      disabled={validDraw("2024-11").length > 0 ? true : false}
                    >
                      November 2024
                    </option>
                    <option
                      value={`2024-12`}
                      disabled={validDraw("2024-12").length > 0 ? true : false}
                    >
                      December 2024
                    </option>
                    <option value={`2025-01`}
                      disabled={validDraw("2025-01").length > 0 ? true : false}
                    >
                      January 2025
                    </option>
                  </select>

                  {errors.drawMonth && (
                    <p className="error">
                      <i className="fas fa-exclamation-circle" /> Select date
                    </p>
                  )}
                </li>
                <li>
                  <button
                    type="button"
                    className="form-control"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                      padding: "5px",
                      width: "25rem",
                    }}
                  >
                    <span>
                      <i className="fas fa-file"></i>
                    </span>
                    <CSVLink
                      headers={headers}
                      data={sampleFile}
                      filename="sample.csv"
                      className="downloand-link"
                      style={{ textDecoration: "none" }}
                    >
                      Download CSV example file
                    </CSVLink>
                  </button>
                </li>
              </ul>
            </div>
            <br />
            <div className="chart" style={{ border: "2px dotted #cbd4db" }}>
              <CsvReader loaddata={setFile} {...register("file")} />
            </div>
            <button className="btn" type="submit" disabled={!file ? true : false}>
              Submit
            </button>
          </div>
        </form>
        {modal && (
          <div
            className="modal fade warning"
            id="Modal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={styles.modal}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div
                  className="modal-header"
                  style={{ flexDirection: "row-reverse" }}
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    style={{ flexDirection: "row-reverse", border: "none" }}
                    onClick={() => setModal(false)}
                  >
                    <span
                      aria-hidden="true"
                      style={{ fontWeight: "bold", backgroundColor: "#fff" }}
                    >
                      X
                    </span>
                  </button>
                </div>
                <div className="modal-body">
                  <h3
                    style={{
                      padding: "10px 10px 10px 0px",
                      marginRight: "5rem",
                    }}
                  >
                    Submit file ?
                  </h3>
                  <h5 style={{ textAlign: "left", width: "75%" }}>
                    Are you sure you would like to submit the uploaded file ?
                  </h5>
                  <br />
                  <div
                    className="main__body main__body--flex"
                    style={{ flexDirection: "row-reverse" }}
                  >
                    <div
                      className="form__footer"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <button className="btn" onClick={() => setModal(false)}>
                        Cancel
                      </button>
                      <button
                        className="btn"
                        style={{ marginLeft: "15px" }}
                        onClick={() => handleUpload()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  );
}

export default UploadUuids;
