import React, { useEffect } from "react";
import "./Toast.css";

export interface ToastProps {
  id: string;
  destroy: () => void;
  content: string;
  duration?: number;
  error: any;
}

const Toast: React.FC<ToastProps> = (props) => {
  const { destroy, content, duration = 0, id, error } = props;

  useEffect(() => {
    if (!duration) return;
    const timer = setTimeout(() => {
      destroy();
    }, duration);
    return () => clearTimeout(timer);
  }, [destroy, duration]);

  return (
    <div className={error === true ? "toast-header-error" : "toast-header"}>
      <i
        className={
          error === true ? "fas fa-times-circle" : "fas fa-check-circle"
        }
        aria-hidden="true"
      ></i>
      <p className="toast-content">{props.content}</p>
      <button onClick={destroy} className="toast-button">
        <span>
          <img
            src="assets/images/ico-toast-close.png"
            alt="Close"
            className="toast-close"
          />
        </span>
      </button>
    </div>
  );
};

export default Toast;
