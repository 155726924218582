import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import CustomLink from "../CustomLink";

function SideBar() {
  const [profile, setProfile] = useState("");

  const getProfile = async () => {
    let user = await Auth.currentAuthenticatedUser();
    console.log(user.attributes.profile);
    setProfile(user.attributes.profile);
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
     <nav className="nav sidebar__nav ">
        <ul className="">
          {profile === "Mosaic" ? (
            <CustomLink to="draws">
              <img width="30" src="/assets/images/svg/ico-home.svg"></img>
              <span>Home</span>
            </CustomLink>
          ) : (
            <CustomLink to="home">
              <img width="30" src="/assets/images/svg/ico-home.svg"></img>
              <span>Home</span>
            </CustomLink>
          )}

          {profile === "Mosaic" ? (
            <CustomLink to="uploadUuids">
              <img width="30" src="/assets/images/svg/upload.svg"></img>
              <span>Upload UUIDs</span>
            </CustomLink>
          ) : (
            <CustomLink to="mailInParticipants">
              <img width="30" src="/assets/images/svg/ico-user.svg"></img>
              <span>Mail-ins</span>
            </CustomLink>
          )}

          {profile === "Mosaic" ? (
            <CustomLink to="importPrizePoolInfo">
              <img width="30" src="/assets/images/svg/ico-user.svg"></img>
              <span>Import prize pool</span>
            </CustomLink>
          ) : (
            <CustomLink to="drawGrandPrizePool">
              <img width="30" src="/assets/images/svg/ico-users.svg"></img>
              <span>Draw grand prize</span>
            </CustomLink>
          )}

          {profile === "Mosaic" ? (
            <CustomLink to="viewWinners">
              <img width="30" src="/assets/images/svg/ico-star.svg"></img>
              <span>View Winners</span>
            </CustomLink>
          ) : (
            <CustomLink to="winnerSelection">
              <img width="30" src="/assets/images/svg/ico-star.svg"></img>
              <span>Winner selection</span>
            </CustomLink>
          )}
        </ul>
      </nav>
    </>
  );
}

export default SideBar;
