import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { PageLoader, Table } from "../../../Components";
import { TableColumns } from "./HomeCol";
import { campaignClient } from "../../../api";
import _ from "lodash";
import { BATCH_STATUS } from "../../../helpers/hooks";

function Home() {
  useEffect(() => {
    drawsRefetch();
  }, []);

  const {
    isLoading: isLoadingDraws,
    data: draws,
    refetch: drawsRefetch,
  } = useQuery("getDrawMonths", async () => {
    const res: any = await campaignClient.call("getDrawMonths", {});
    const destructuredDrawsData: any = _.map(res, (obj) => {
      return _.mapValues(obj, (val) => {
        return val[Object.keys(val)[0]];
      });
    });
    return destructuredDrawsData;
  });

  const [filterTable, setFilterTable] = useState("");

  const data = useMemo(() => {
    if (draws && draws.length > 0) {
      return draws.map((draw: any) => {
        return {
          drawMonth: draw.sk,
          status: BATCH_STATUS[`${draw.batchStatus}`],
          uuidUploadDate: new Date(draw.createTime).toLocaleString(),
          prizePoolUploadDate: draw.prizePoolUploadTime,
        };
      });
    }
  }, [draws]);

  const columns = TableColumns();

  if (isLoadingDraws) {
    return <PageLoader />;
  }

  return !isLoadingDraws && draws && draws.length > 0 ? (
    <>
      <div className="main__head">
        <h2 className="main__title">Contest draw months</h2>
      </div>

      <div className="tabs__body mt-5">
        <div className="table table--alt table--tabs table--big">
          <Table
            columns={columns}
            data={data ? data : []}
            tablePageSize={15}
            filterValue={filterTable}
          />
        </div>
      </div>
    </>
  ) : (
    <p>Currently there are no any draw months to display.</p>
  );
}

export default Home;
