import { useState, useEffect, useMemo } from "react";
import { CSVLink } from "react-csv";
import { campaignClient } from "../../../api";
import { Modal, PageLoader, Table } from "../../../Components";
import { TableColumns } from "./ImportPrizePoolInfoCol";
import CsvReader from "../../../Components/CSVReader";
import { toast } from "../../../Components/Toast/ToastManager";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { emailRegex } from "../../../helpers/utils";
import { decrypt } from "../../../helpers/hooks";
import { useQuery } from "react-query";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

type csvData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  uuid: string;
};

const headers = [
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Email", key: "email" },
  { label: "Phone Number", key: "phone" },
  { label: "UUID", key: "uuid" },
];

type FormData = {
  drawMonth: string;
  file: File;
};

function ImportPrizePoolInfo() {
  const navigate = useNavigate();
  const [file, setFile] = useState<any>(null);
  const [filterTable, setFilterTable] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState<any>([]);
  const [submitModal, setSubmitModal] = useState(false);
  const {
    register,
    watch,
    handleSubmit,
    getFieldState,
    formState: { errors },
  } = useForm<FormData>({ mode: "all" });

  const drawmonthWatch = watch("drawMonth")

  const { isLoading: loadingDraw, data: drawWinners } = useQuery(
    ["getPrizePoolInfo", drawmonthWatch],
    async () => {
      const res: any = await campaignClient.call("getPrizePoolInfo", {
        drawMonth: watch("drawMonth"),
      });
      return res
    }, { enabled: !!drawmonthWatch }
  );

  const onSubmit = handleSubmit((data: FormData) => {
    setData(file.data);
    setSubmitModal(true);
  });

  const tableData = useMemo(() => {
    if (file) {
      return [
        {
          name: file.file?.name,
          number: file?.data?.filter((row: any[]) => row[0]).length - 1,
          date: new Date().toLocaleString(),
        },
      ];
    }
  }, [file]);


  const uuids = useMemo(() => {

    if (drawWinners) {

      let uuids = (drawWinners.length > 0 ? drawWinners[0]!.uniqid!.S.split(",") : []);

      return uuids.map((id: any) => {
        return {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          uuid: id,
        }
      });
    }
  }, [drawWinners])

  const handleUpload = async () => {
    if (submitting) return;
    setSubmitting(true);

    try {
      const res =
        data &&
        data
          .slice(1)
          .filter((r: any) => r.length === 5)
          .map((row: any) => {
            // Check for empty fields or fields containing only whitespace
            if (
              !row ||
              !row[0].trim() ||
              !row[1].trim() ||
              !row[2].trim() ||
              !row[4].trim()
            ) {
              // Handle invalid row or field data
              const errorMessage = row
                ? "Invalid row data: please ensure all fields are filled and email format is correct"
                : "Invalid row data";
              window.vex.dialog.alert({
                unsafeMessage: errorMessage,
              });
              throw new Error(errorMessage);
            }

            // // Check for valid email format
            if (!emailRegex.test(row[2])) {
              window.vex.dialog.alert({
                unsafeMessage: `Invalid email format for user ${row[0]} ${row[1]}`,
              });
              throw new Error(
                `Invalid email format for user ${row[0]} ${row[1]}`
              );
            }

            // Check for valid UUID and UUID length
            const uuidsFromFile = data
              .slice(1)
              .filter((row: any) => row[4])
              .map((row: any) => row[4]);

              console.log(uuidsFromFile,uuids)
            if (
              uuids.length !== uuidsFromFile.length ||
              !uuids.every((uuid: any) => uuidsFromFile.includes(uuid.uuid))
            ) {
              window.vex.dialog.alert({
                unsafeMessage:
                  "The UUIDs in the CSV file do not match the ones obtained from the draw",
              });
              throw new Error(
                "The UUIDs in the CSV file do not match the ones obtained from the draw"
              );
            }

         //   setUuids([uuids, row[4]]);
            return {
              firstName: row[0].trim(),
              lastName: row[1].trim(),
              email: row[2].trim(),
              uuid: row[4].trim(),
            };
          });

      const registerPromises = await res.map((row: any) =>
        campaignClient.call("register", {
          firstName: row.firstName,
          lastName: row.lastName,
          email: row.email,
          phone: row.phone,
          metadata: {
            uuid: row.uuid,
            drawMonth: drawmonthWatch,
            prizePoolUploadTime: new Date().toLocaleString(),
          },
        })
      );

      const responses = await Promise.all(registerPromises);

      if (responses.length) {
        await campaignClient.call("sendEmailMosaic", {
          placeHolder: "confPrize",
          subjectLine: "Action Required",
        });

        await campaignClient.call("sendEmailWily", {
          placeHolder: "confInfo",
          subjectLine: "Draw Complete",
          btn: true,
        });
      }

      setSubmitting(false);
      setSubmitModal(false);

      toast.show({
        content: `File submitted.`,
        duration: 3000,
        error: false,
      });

      navigate("/draws");
    } catch (e) {
      setSubmitting(false);
      setSubmitModal(false);

      toast.show({
        content: `File submission failed.`,
        duration: 3000,
        error: true,
      });

      throw e;
    }
  };

  const columns = TableColumns();

  if (submitting || loadingDraw) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="main__head">
        <h2 className="main__title">Import prize pool info</h2> <br />
        <h4 style={{ maxWidth: "55%", textAlign: "left" }}>
          To upload a file containing user prize pool info, select the draw
          month then select the file from your computer and drag and drop it
          into the designated area onscreen. Once your file is uploaded, please
          ensure the file is correct before clicking the 'submit' button below.
        </h4>
        <br />
        <h3>Grand prize pool UUIDs</h3>
        <div className="main__body main__body--flex main__body--flex-alt">
          <div className="boxes-info">
            <div
              style={{
                width: "35%",
              }}
            >
              <div
                style={{
                  border: "1px solid black",
                  maxHeight: "22rem",
                  overflow: "auto",
                  paddingLeft: "1.2rem",
                }}
              >
                <ol>
                  {uuids?.map((uuid: any, index: number) => (
                    <li style={{ padding: "0.1rem", width: "100%" }} key={uuid.id}>{uuid.uuid}</li>
                  ))}
                </ol>
              </div>

              {/* {uuids.length !== 0 && getFieldState("drawMonth").isDirty && (
                <li>
                  <button
                    className="form-control"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      width: "15rem",
                    }}
                    onClick={() => handleExportUuids()}
                  >
                    Export UUIDs
                  </button>
                </li>
              )} */}
            </div>
          </div>
          <br />
        </div>
      </div>

      <form onSubmit={onSubmit}>
        <div className="main__body main__body--flex main__body--flex-alt">
          <div className="boxes-info">
            <ul
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <li style={{ width: "25%" }}>
                <h5>Draw month</h5>
                <select
                  className="form-control-select"
                  {...register("drawMonth", { required: true })}
                >
                  <option value="">Select</option>
                  <option value={`2024-02`}>
                    February 2024
                  </option>
                  <option value={`2024-03`}>
                    March 2024
                  </option>
                  <option value={`2024-04`}>
                    April 2024
                  </option>
                  <option value={`2024-05`}>
                    May 2024
                  </option>
                  <option value={`2024-06`}>
                    June 2024
                  </option>
                  <option value={`2024-07`}>
                    July 2024
                  </option>
                  <option value={`2024-08`}>
                    August 2024
                  </option>
                  <option value={`2024-09`}>
                    September 2024
                  </option>
                  <option value={`2024-10`}>
                    October 2024
                  </option>
                  <option value={`2024-11`}>
                    November 2024
                  </option>
                  <option value={`2024-12`}>
                    December 2024
                  </option>
                  <option value={`2025-01`}>
                    January 2025
                  </option>
                </select>

                {errors.drawMonth && (
                  <p className="error">
                    <i className="fas fa-exclamation-circle" /> Select date
                  </p>
                )}

                {uuids?.length === 0 && getFieldState("drawMonth").isDirty && (
                  <p className="error">
                    <i className="fas fa-exclamation-circle" /> No draws are
                    conducted for the selected month.
                  </p>
                )}
              </li>
              {uuids?.length !== 0 && getFieldState("drawMonth").isDirty ? (
                <li>
                  <button
                    type="button"
                    className="form-control"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                      padding: "5px",
                      width: "28rem",
                    }}
                  >
                    <span>
                      <i className="fas fa-file"></i>
                    </span>
                    <CSVLink
                      headers={headers}
                      data={uuids}
                      filename="sample.csv"
                      className="downloand-link"
                      style={{ textDecoration: "none" }}
                    >
                      Download UUIDs prefilled CSV file
                    </CSVLink>
                  </button>
                </li>
              ) : (
                <></>
              )}
            </ul>
          </div>
          <br />
          <div className="chart" style={{ border: "2px dotted #cbd4db" }}>
            <CsvReader loaddata={setFile} {...register("file")} />
            {/* {errors.file && (
              <p className="error" style={{ width: "none" }}>
                <i className="fas fa-exclamation-circle" /> Please select a file
                to upload
              </p>
            )} */}
          </div>
          <button className="btn" type="submit">
            Submit
          </button>
        </div>
      </form>

      {tableData && file ? (
        <div className="tabs__body mt-5">
          <div className="table table--alt table--tabs table--big">
            <Table
              columns={columns}
              data={file ? tableData : []}
              tablePageSize={15}
              filterValue={filterTable}
            />
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* SubmitModal */}
      {submitModal && (
        <Modal
          handleClose={() => {
            setSubmitModal(false);
          }}
          notification={true}
          message={"Are you sure you would like to submit the uploaded file ?"}
          modalType="submitModal"
          header="Submit file ?"
          handleUpload={() => handleUpload()}
        />
      )}
    </>
  );
}

export default ImportPrizePoolInfo;
