import { Route, Navigate } from "react-router-dom";
import { Layout } from "../Components";
import { DrawGrandPrizePool, Draws, Home, ImportPrizePoolInfo, MailInParticipants, UploadUuids, ViewWinners, WinnerSelection } from "../Pages";
import WinnerDetails from "../Pages/Mosaic/WinnerDetails";

export default (
    <>
        <Route path="/" element={<Layout />}>
            {/* Mosaic Routes */}
            <Route path="draws" element={<Draws />} />
            <Route path="uploadUuids" element={<UploadUuids />} />
            <Route path="importPrizePoolInfo" element={<ImportPrizePoolInfo />} />
            <Route path="viewWinners" element={<ViewWinners />} />
            <Route path="winnerDetails/:sk" element={<WinnerDetails />} />

            {/* Wily Routes */}
            <Route path="home" element={<Home />} />
            <Route path="mailInParticipants" element={<MailInParticipants />} />
            <Route path="drawGrandPrizePool" element={<DrawGrandPrizePool />} />
            <Route path="winnerSelection" element={<WinnerSelection />} />
        </Route>
    </>
);
