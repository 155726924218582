export function byKey<T>(records: T[], getKey: (t: T) => string) {
  return records.reduce((prev, curr) => {
    const key = getKey(curr);
    if (!prev[key]) {
      prev[key] = [];
    }
    prev[key].push(curr);
    return prev;
  }, {} as { [key: string]: T[] });
}

export const emailRegex =
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
export const looseNorthAmericanPhoneRegex =
  /^\+?1?[.() -]{0,2}[2-9][0-8][0-9][.() -]{0,2}[2-9][0-9]{2}[.() -]?[0-9]{4}$/;
export const canadianPostalCodeRegex = /^[a-z][0-9][a-z][ -]?[0-9][a-z][0-9]$/i;
export const americanZipCodeRegex = /^[0-9]{5}(-[0-9]{4})?$/;
export const stringRegex =
  /^[a-zA-ZÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ \-']{2,64}$/i;
