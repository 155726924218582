import { TableProps } from "react-table";

export const TableColumns = () => {
  const COLUMNS = [
    {
      Header: "#",
      accessor: "colNumber",
      width: "3.6%",
      Cell: (TableInfo: TableProps) => {
        const nmb = TableInfo.row.index + 1;
        return nmb;
      },
    },
    {
      Header: "Draw month",
      accessor: "drawMonth",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].drawMonth
          ? TableInfo.data[TableInfo.row.index].drawMonth
          : "N/A";
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].status
          ? TableInfo.data[TableInfo.row.index].status
          : "N/A";
      },
    },
    {
      Header: "UUID upload date",
      accessor: "uuidUploadDate",
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].uuidUploadDate
          ? TableInfo.data[TableInfo.row.index].uuidUploadDate.toLocaleString()
          : "NA";
        return dateString;
      },
    },
    {
      Header: "Prize pool upload date",
      accessor: "prizePoolUploadDate",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].prizePoolUploadDate
          ? TableInfo.data[TableInfo.row.index].prizePoolUploadDate
          : "N/A";
      },
    },
    {
      Header: "#",
      accessor: "test",
      Cell: (TableInfo: TableProps) => {
        return (
          <a
            style={{ textDecoration: "none", color: "blue" }}
            href="/drawGrandPrizePool"
          >
            Draw grand prize pool
          </a>
        );
      },
    },
  ];
  return COLUMNS;
};
