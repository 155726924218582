import { TableProps } from "react-table";

export const TableColumns = () => {
  const COLUMNS = [
    {
      Header: "#",
      accessor: "colNumber",
      width: "3.6%",
      Cell: (TableInfo: TableProps) => {
        const nmb = TableInfo.row.index + 1;
        return nmb;
      },
    },
    {
      Header: "UUID",
      accessor: "uuid",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].uuid
          ? TableInfo.data[TableInfo.row.index].uuid
          : "N/A";
      },
    },
    {
      Header: "Draw month",
      accessor: "drawMonth",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].drawMonth
          ? TableInfo.data[TableInfo.row.index].drawMonth
          : "N/A";
      },
    },
  ];
  return COLUMNS;
};
