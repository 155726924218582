import { useMemo, useState, useEffect } from "react";
import { useQuery } from "react-query";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { Modal, PageLoader, Table } from "../../../Components";
import { TableColumns } from "./MailInCol";
import { campaignClient } from "../../../api";
import React from "react";
import _ from "lodash";

type MailIns = {
  uniqid: string;
  sk: string;
  dataKey: string;
  idType: string;
  sessionKey?:string;
};

function MailInParticipants() {
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [numberOfMailInsToAdd, setNumberOfMailInsToAdd] = useState(0);

  const {
    isLoading: isLoadingMailIns,
    data: mailIns,
    refetch: mailInsRefetch,
  } = useQuery(["getMailIns1", {}], async () => {
    const res: MailIns[] = await campaignClient.call("getMailIns", {});
    return res;
  });

  const {
    isLoading: isLoadingparticipants,
    data: participants,
    refetch: refetchParticipants,
  } = useQuery(["getParticipants", {}], async () => {
    const res: MainDbReturnTypes.Participants = await campaignClient.call(
      "getParticipants",
      {}
    );
    return res.participants;
  });

  const handleAdd = () => {
    setNumberOfMailInsToAdd(numberOfMailInsToAdd + 1);
  };

  const handleSubtract = () => {
    setNumberOfMailInsToAdd(numberOfMailInsToAdd - 1);
  };

  const [filterTable, setFilterTable] = useState("");
  const [drawMonth, setDrawMonth] = useState("");
  const [uuid, setUuid] = useState("");

  const data = useMemo(() => {
    if (mailIns && participants) {
      const participants_mailins = participants.filter(
        (p) => p.metadata.mailIn
      );

      const mergeMailins = mailIns.map((m) => {
        const existing_participant = participants_mailins.find(
          (p) => p.sessionKey === m.sessionKey
        );
        if (existing_participant) {
          return {
            uuid: existing_participant.metadata.uniqid,
            dataKey: existing_participant.metadata.drawMonth,
            drawMonth: m.dataKey.split("#")[2],
            firstName: existing_participant.firstName
              ? existing_participant.firstName
              : "",
            lastName: existing_participant.lastName
              ? existing_participant.lastName
              : "",
            email: existing_participant.email ? existing_participant.email : "",
            phone: existing_participant.phone ? existing_participant.phone : "",
            status:existing_participant.metadata.status,
            winnerMonth:existing_participant.metadata.winnerMonth||""
          };
        } else {
          return {
            uuid: m.uniqid,
            dataKey: m.dataKey,
            drawMonth: m.dataKey.split("#")[2],
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            winnerMonth:"",
          };
        }
      });

      return mergeMailins;


      
    } else {
      return [];
    }
  }, [mailIns, participants]);

  const functions = {
    setDrawMonth,
    setUuid,
    setEditModal,
  };

  const COLUMNS = React.useMemo(() => TableColumns(functions), []);

  if (isLoadingMailIns || isLoadingparticipants) {
    return <PageLoader />;
  }

  return (
    !isLoadingMailIns &&
    !isLoadingparticipants && (
      <>
        <div className="main__head">
          <h2 className="main__title">Mail-in participants</h2>
          <div className="main__actions">
            <div className="search search--alt">
              <div className="search__row search__row--input">
                <button
                  className="btn btn--medium btn--mobile-small"
                  onClick={() => setAddModal(true)}
                >
                  Add mail in participants
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="main__body">
          <div className="tabs js-tabs">
            <div className="tabs__head">
              <nav className="tabs__nav">
                <ul></ul>
              </nav>
            </div>
            {data?.length > 0 ? (
              <div className="tabs__body mt-5">
                <div className="table table--alt table--tabs table--big">
                  <Table
                    columns={COLUMNS}
                    data={data ? data : []}
                    tablePageSize={15}
                    filterValue={filterTable}
                  />
                </div>
              </div>
            ) : (
              <>Currently there are no an mail-in participants.</>
            )}
          </div>
        </div>

        {/* AddModal */}
        {addModal && (
          <Modal
            handleAdd={() => handleAdd()}
            handleSubtract={() => handleSubtract()}
            handleClose={() => setAddModal(false)}
            notification={true}
            message={
              "Please select the draw month and enter the number of mail-in participants you would like to add."
            }
            modalType="MailInAddPopUp"
            header="Add mail-in participants"
            number={numberOfMailInsToAdd}
            mailInsRefetch={mailInsRefetch}
            mailIns={mailIns}

          />
        )}

        {/* EditModal */}
        {editModal && (
          <Modal
            handleClose={() => {
              setEditModal(false);
            }}
            notification={true}
            message={
              "Please enter the info for the mail-in participant in the fields below."
            }
            modalType="MailInEditPopUp"
            header={`Edit mail-in participant (${drawMonth}#${uuid})`} 
            uuid={uuid}
            drawMonth={drawMonth!}
            refetchParticipants={refetchParticipants}
          />
        )}
      </>
    )
  );
}

export default MailInParticipants;
