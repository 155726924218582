import { useMemo, useState, useEffect } from "react";
import { PageLoader, Table } from "../../../Components";
import _ from "lodash";
import { TableColumns } from "./DrawGrandCol";
import { useForm } from "react-hook-form";
import { toast } from "../../../Components/Toast/ToastManager";
import { campaignClient } from "../../../api";
import { useQuery } from "react-query";
import { decrypt, encrypt } from "../../../helpers/hooks";

type RegisterFormData = {
  drawMonth: string;
  drawNumber: number;
};

function DrawGrandPrizePool() {
  const [loading, setLoading] = useState(false);
  const columns = TableColumns();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterFormData>({ mode: "all" });
  const [draws, setDraws] = useState([]);

  const { isLoading: isLoadingDraws, data: drawsInfo } = useQuery(
    "getDrawMonths",
    async () => {
      const res: any = await campaignClient.call("getDrawMonths", {});
      const destructuredDrawsData: any = _.map(res, (obj) => {
        return _.mapValues(obj, (val) => {
          return val[Object.keys(val)[0]];
        });
      });
      return destructuredDrawsData;
    }
  );

  const handleDraw = async (data: RegisterFormData) => {
    if (loading) return;
    setLoading(true);

    try {
      const res: any = await campaignClient.call("drawGrandPool", {
        drawMonth: data.drawMonth,
        drawNumber: data.drawNumber,
      });

      if (!res.error) {
        const destructuredDrawsData: any = _.map(res, (obj) => {
          return _.mapValues(obj, (val) => {
            return val[Object.keys(val)[0]];
          });
        });
        setDraws(destructuredDrawsData);

        await campaignClient.call("sendEmailMosaic", {
          placeHolder: "action",
          subjectLine: "Upload Prize",
          btn: true,
          drawCount: data.drawNumber,
        });

        await campaignClient.call("sendEmailWily", {
          placeHolder: "confDrow",
          subjectLine: "Draw Complete",
        });


        toast.show({
          content: `Grand prize has been drawn successfully.`,
          duration: 3000,
          error: false,
        });
      } else {
        toast.show({
          content: `${res.message}`,
          duration: 3000,
          error: true,
        });
      }
      setLoading(false);

    } catch (e) {
      setLoading(false);

      toast.show({
        content: `Grand prize draw is failed.`,
        duration: 3000,
        error: true,
      });

      console.log(e);
      throw e;
    }
  };

  const data = useMemo(() => {
    if (draws && draws.length > 0) {
      return draws.map((draw: any) => {
        return {
          drawMonth: draw.sk.split("#")[0],
          uuid: draw.uniqid,
        };
      });
    }
  }, [draws]);

  const validDraw = (mon: string) => {
    return (
      drawsInfo &&
      drawsInfo.filter(
        (drawsInfo: any) =>
          drawsInfo.sk === mon && drawsInfo.prizePoolUploadTime
      )
    );
  };

  const [filterTable, setFilterTable] = useState("");

  if (loading || isLoadingDraws) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="main__head">
        <h2 className="main__title">Draw Grand Prize Pool</h2> <br />
        <h4 style={{ maxWidth: "55%", textAlign: "left" }}>
          Click the button below to draw the grand prize pool draw for the
          current contest draw month. Once complete, the 10 UUIDs will be
          displayed below and an email notification will automaticallty be sent
          to the Mosaic user responsibile for providing the participant info.
        </h4>
      </div>
      <div className="main__form form">
        <div className="form__body">
          <form onSubmit={handleSubmit(handleDraw)}>
            <div className="formContent">
              <div className="form-group">
                <label className="form__label">Draw month</label>
                <select
                  className="form-control-select"
                  style={{ width: "25%" }}
                  {...register("drawMonth", {
                    required: {
                      value: true,
                      message: "Please select a draw month.",
                    },
                  })}
                >
                  <option value="">Select</option>
                  <option value={`2024-02`}
                    disabled={validDraw("2024-02").length > 0 ? true : false}
                    >
                    February 2024
                  </option>
                  <option
                    value={`2024-03`}
                    disabled={validDraw("2024-03").length > 0 ? true : false}
                  >
                    March 2024
                  </option>
                  <option
                    value={`2024-04`}
                    disabled={validDraw("2024-04").length > 0 ? true : false}
                  >
                    April 2024
                  </option>
                  <option
                    value={`2024-05`}
                    disabled={validDraw("2024-05").length > 0 ? true : false}
                  >
                    May 2024
                  </option>
                  <option
                    value={`2024-06`}
                    disabled={validDraw("2024-06").length > 0 ? true : false}
                  >
                    June 2024
                  </option>
                  <option
                    value={`2024-07`}
                    disabled={validDraw("2024-07").length > 0 ? true : false}
                  >
                    July 2024
                  </option>
                  <option
                    value={`2024-08`}
                    disabled={validDraw("2024-08").length > 0 ? true : false}
                  >
                    August 2024
                  </option>
                  <option
                    value={`2024-09`}
                    disabled={validDraw("2024-09").length > 0 ? true : false}
                  >
                    September 2024
                  </option>
                  <option
                    value={`2024-10`}
                    disabled={validDraw("2024-10").length > 0 ? true : false}
                  >
                    October 2024
                  </option>
                  <option
                    value={`2024-11`}
                    disabled={validDraw("2024-11").length > 0 ? true : false}
                  >
                    November 2024
                  </option>
                  <option
                    value={`2024-12`}
                    disabled={validDraw("2024-12").length > 0 ? true : false}
                  >
                    December 2024
                  </option>
                  <option value={`2025-01`}
                     disabled={validDraw("2025-01").length > 0 ? true : false}
                    >
                    January 2025
                  </option>
                </select>
                {errors.drawMonth && (
                  <p className="error" style={{ width: "25%" }}>
                    <i className="fas fa-exclamation-circle" />{" "}
                    {errors.drawMonth.message}
                  </p>
                )}
              </div>

              <div className="form-group">
                <label className="form__label">No. of UUID's to draw</label>
                <select
                  className="form-control-select"
                  style={{ width: "25%" }}
                  {...register("drawNumber", {
                    required: {
                      value: true,
                      message: "Please select number of UUID's to draw.",
                    },
                  })}
                >
                  <option value="">Select</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
                {errors.drawNumber && (
                  <p className="error" style={{ width: "25%" }}>
                    <i className="fas fa-exclamation-circle" />{" "}
                    {errors.drawNumber.message}
                  </p>
                )}
              </div>
            </div>
            <br />
            <div className="boxes-info">
              <button className="btn" type="submit">
                Draw grand prize pool
              </button>
            </div>
            {loading && <PageLoader />}
          </form>
        </div>
      </div>
      {draws!.length > 0 ? (
        <div className="tabs__body mt-5">
          <div className="table table--alt table--tabs table--big">
            <Table
              columns={columns}
              data={data ? data : []}
              tablePageSize={15}
              filterValue={filterTable}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default DrawGrandPrizePool;
