import { useMemo, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { campaignClient } from "../../../api";
import { PageLoader } from "../../../Components";

type sentEmailResult = {
  campaignKey: string;
  creationTime: Date;
  emailTemplateKey: string;
  fromEmail: string;
  fromName: string;
  lastUpdateTime: Date;
  operationId: string;
  targets: any[];
};

type sentEmailResults = {
  sentEmailResults: sentEmailResult[];
};

interface EmailTemplateHasSubj extends MainDbReturnTypes.EmailTemplate {
  subject: string;
}
type RegisterFormData = {
  firstName: string | null;
  lastName: string;
  email: string;
  title: string | any;
  brokerageName: string | any;
};

function WinnerDetails() {
  const { sk } = useParams();

  const navigate = useNavigate();

  const { isLoading: isLoadingWinners, data: prizeWinners } = useQuery(
    "getPrizeWinners",
    async () => {
      const res: MainDbReturnTypes.PrizeWinners = await campaignClient.call(
        "getPrizeWinners",
        { sessionKey: sk! }
      );
      return res.prizeWinners;
    }
  );

  useEffect(() => {
  }, [prizeWinners]);

  if (isLoadingWinners) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="main__head">
        <h2 className="main__title">Winner details</h2>
        <div className="main__actions">
          <a
            href="#"
            className="btn-back"
            onClick={(e) => {
              e.preventDefault();
              navigate("/viewWinners");
            }}
          >
            <svg className="ico-arrow">
              <image xlinkHref="/assets/images/svg/ico-arrow.svg"></image>
            </svg>
          </a>
        </div>
      </div>

      <div className="main__body main__body--flex">
        <div className="main__form form">
          <div className="form__body">
            <div className="form__row1">
              <label className="form__label" style={{ paddingRight: "15rem" }}>
                Draw month
              </label>
              <label>
                {prizeWinners![0].participant.metadata.drawMonth || "N/A"}
              </label>
            </div>
            <div className="form__row1">
              <label className="form__label" style={{ paddingRight: "15rem" }}>
                Claim date/ time
              </label>
              <label>
                {new Date(
                  prizeWinners![0].declarationAndRelease!.creationTime!
                ).toLocaleString() || "N/A"}
              </label>
            </div>
            <div className="form__row1">
              <label className="form__label" style={{ paddingRight: "15rem" }}>
                First name
              </label>
              <label>{prizeWinners![0].declarationAndRelease!.firstName! || "N/A"}</label>
            </div>
            <div className="form__row1">
              <label className="form__label" style={{ paddingRight: "15rem" }}>
                Last name
              </label>
              <label>{prizeWinners![0].declarationAndRelease!.lastName! || "N/A"}</label>
            </div>
            <div className="form__row1">
              <label className="form__label" style={{ paddingRight: "15rem" }}>
                Email
              </label>
              <label>{prizeWinners![0].participant!.email! || "N/A"}</label>
            </div>
            <div className="form__row1">
              <label className="form__label" style={{ paddingRight: "15rem" }}>
                Phone
              </label>
              <label>{prizeWinners![0].declarationAndRelease!.phone! || "N/A"}</label>
            </div>
            <div className="form__row1">
              <label className="form__label" style={{ paddingRight: "15rem" }}>
                Address 1
              </label>
              <label>{prizeWinners![0].declarationAndRelease!.address! || "N/A"}</label>
            </div>
            <div className="form__row1">
              <label className="form__label" style={{ paddingRight: "15rem" }}>
                Address 2
              </label>
              <label>{prizeWinners![0].declarationAndRelease!.address2! || "N/A"}</label>
            </div>
            <div className="form__row1">
              <label className="form__label" style={{ paddingRight: "15rem" }}>
                City
              </label>
              <label>{prizeWinners![0].declarationAndRelease!.city! || "N/A"}</label>
            </div>
            <div className="form__row1">
              <label className="form__label" style={{ paddingRight: "15rem" }}>
                Province/ territory
              </label>
              <label>{prizeWinners![0].declarationAndRelease!.province! || "N/A"}</label>
            </div>
            <div className="form__row1">
              <label className="form__label" style={{ paddingRight: "15rem" }}>
                Postal code
              </label>
              <label>{prizeWinners![0].declarationAndRelease!.postal! || "N/A"}</label>
            </div>
            <div className="form__row1">
              <label className="form__label" style={{ paddingRight: "15rem" }}>
                Date of birth
              </label>
              <label>
                {prizeWinners![0].participant!.metadata!.dob || "N/A"}
              </label>
            </div>{" "}
            <br />
            <br />
            <div className="form__row1">
              <label className="form__label" style={{ paddingRight: "15rem" }}>
                Signature
              </label>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={prizeWinners![0].participant!.metadata!.sign!}
                style={{
                  border: "0.1rem solid #cecece",
                  padding: "25px 200px 25px 200px",
                }}
              />
            </div>
          </div>
          {/* <div className="form__actions"></div> */}
        </div>
        {/* <div className="main__content"></div> */}
      </div>
    </>
  );
}

export default WinnerDetails;
