export const TableColumns = () => {
  const COLUMNS = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "# of entries",
      accessor: "number",
    },
    {
      Header: "Date/ time",
      accessor: "date",
    },
  ];
  return COLUMNS;
};
