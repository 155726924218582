import dayjs from "dayjs";
import React, { useState } from "react";
import { TableProps } from "react-table";

export const TableColumns = (fns: {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setResetModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSessionKey: React.Dispatch<React.SetStateAction<string>>;
  setWinnerMonth: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const COLUMNS = [
    {
      Header: "No.",
      accessor: "colNumber",
      width: "3.6%",
      Cell: (TableInfo: TableProps) => {
        const nmb = TableInfo.row.index + 1;
        return nmb;
      },
    },
    {
      Header: "Draw month",
      accessor: "drawMonth",
      width: "5.1%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].drawMonth
          ? TableInfo.data[TableInfo.row.index].drawMonth
          : "N/A";
      },
    },
    {
      Header: "Winner month",
      accessor: "winnerMonth",
      width: "5.1%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].winnerMonth
          ? TableInfo.data[TableInfo.row.index].winnerMonth
          : "N/A";
      },
    },
    {
      Header: "Days left",
      accessor: "expiryTime",
      width: "5.1%",
      Cell: (TableInfo: TableProps) => {
        const expiry = TableInfo.data[TableInfo.row.index].emailExpiry

        const daysleft = expiry ? dayjs(expiry.split(",")[0]).diff(dayjs(), 'days') + 1 : null

        return daysleft === null ? "" : daysleft > 0 ? daysleft : "Expired"
      },
    },
    {
      Header: "First name",
      accessor: "firstName",
      width: "7.7%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].firstName
          ? TableInfo.data[TableInfo.row.index].firstName
          : "N/A";
      },
    },
    {
      Header: "Last name",
      accessor: "lastName",
      width: "7.7%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].lastName
          ? TableInfo.data[TableInfo.row.index].lastName
          : "N/A";
      },
    },
    {
      Header: "Email",
      accessor: "email",
      width: "13.1%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].email
          ? TableInfo.data[TableInfo.row.index].email
          : "N/A";
      },
    },
    {
      Header: "Prize Claimed",
      accessor: "status",
      width: "13.1%",
    },
    {
      Header: "#",
      accessor: "test",
      Cell: (TableInfo: TableProps) => {
        return (
          <button
            style={{
              textDecoration: "none",
              color: "blue",
              background: " none",
              border: "none",
            }}
            disabled={
              TableInfo.data[TableInfo.row.index].claimed ? true : false
            }
            onClick={() => {
              TableInfo.data[TableInfo.row.index].winnerMonth
                ? fns.setResetModal(true)
                : fns.setModal(true);
              fns.setSessionKey(TableInfo.data[TableInfo.row.index].sessionKey);
              TableInfo.data[TableInfo.row.index].winnerMonth ? (
                fns.setWinnerMonth(
                  TableInfo.data[TableInfo.row.index].winnerMonth
                )
              ) : (
                <></>
              );
            }}
          >
            {TableInfo.data[TableInfo.row.index].winnerMonth &&
              TableInfo.data[TableInfo.row.index].claimed
              ? "NA"
              : TableInfo.data[TableInfo.row.index].winnerMonth &&
                !TableInfo.data[TableInfo.row.index].claimed
                ? "Resend Email"
                : "Select winner"}
          </button>
        );
      },
    },
  ];
  return COLUMNS;
};
