import { ComponentPropsToStylePropsMap } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import CryptoJS from "crypto-js";

const useAuth = (authStore: any) => {
  const [isAuth, setIsAuth] = useState({
    auth: false,
    loading: true,
  });

  useEffect(() => {
    const handleVerifyLogin = () => {
      try {
        //await authStore.verifyLogin();

        const user = authStore.getUser();

        if (user.token) {
          setIsAuth({
            auth: true,
            loading: false,
          });
        } else {
          setIsAuth({
            auth: false,
            loading: false,
          });
        }
      } catch (error) {
        console.log("use auth", error);
      }
    };

    handleVerifyLogin();
  }, [authStore]);

  return { isAuth };
};

export { useAuth };

export const useBodyClass = (klassName: string) => {
  useEffect(() => {
    document.body.className = klassName;
  }, [klassName]);

  return () => {
    document.body.className = "";
  };
};

export const BATCH_STATUS: any = {
  "0": "Pending",
  "1": "Processing",
  "2": "File submitted",
  "3": "PrizeDraw",
  "4": "WinnerUpload",
  "5": "WinnerSelected",
  "6": "Finish",
};

export const styles = {
  modal: { display: "block", opacity: 1, backgroundColor: "rgba(0,0,0,0.5)" },
  border: { border: "1px solid black" },
} as const;

export const encrypt = (text: string) => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
};

export const decrypt = (data: string) => {
  return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
};
