import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  emailRegex,
  looseNorthAmericanPhoneRegex,
  stringRegex,
} from "../../helpers/utils";
import { campaignClient } from "../../api";
import { toast } from "../Toast/ToastManager";

interface DataObject {
  dataKey: string;
  sk: string;
  uuid: string;
}

type Imodal = {
  handleClose: Function;
  notification: boolean;
  message: string;
  modalType: string;
  header: any;
  number?: number;
  handleAdd?: Function;
  handleSubtract?: Function;
  handleAddUuids?: Function;
  uuid?: string;
  drawMonth?: string;
  handleUpload?: Function;
  refetchParticipants?: Function;
  mailInsRefetch?: Function;
  mailIns?:any[]
};

type RegisterFormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  drawMonth?: string;
  numberOfMailIns?: number;
  metadata: {
    drawMonth?: string;
    uuid?: string;
  };
};

export const Modal = (props: Imodal) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterFormData>({ mode: "all" });

  const [submitting, setSubmitting] = useState(false);

  const styles = {
    modal: { display: "block", opacity: 1, backgroundColor: "rgba(0,0,0,0.5)" },
    border: { border: "1px solid black" },
  } as const;

  const handleClose = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.handleClose();
  };

  const handleAdd = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.handleAdd && props.handleAdd();
  };

  const handleSubtract = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.handleSubtract && props.handleSubtract();
  };

  const handleAddUuids = async (data: RegisterFormData) => {
    if (submitting) return;
    setSubmitting(true);
    try {
      const objects: DataObject[] = [];
      
      const currentnumber = props.mailIns?.filter(m=>m.sk.split('#')[0]===data.drawMonth
      ).length || 0

      for (let i = currentnumber; i < currentnumber+props.number!; i++) {
        await objects.push({
          dataKey: `uuid#2#${data.drawMonth}`,
          sk: `${data.drawMonth!}#${i + 1}`,
          uuid: `${i + 1}`,
        });
      }

      let res: any = await campaignClient.call("uploadMailIns", {
        items: objects,
      });

      await props.mailInsRefetch!();

      toast.show({
        content: `${objects.length} Mail-in UUIDs has been uploaded successfully.`,
        duration: 3000,
        error: false,
      });

      props.handleClose();

      setSubmitting(false);
      return res;
    } catch (e) {
      setSubmitting(false);

      toast.show({
        content: `Mail-in UUIDs has failed to upload.`,
        duration: 3000,
        error: true,
      });

      console.log(e);
      throw e;
    }
  };

  async function handleRegister(data: RegisterFormData) {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    try {
      const res: any = await campaignClient.call("register", {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        metadata: {
          uuid: props.uuid!,
          drawMonth: props.drawMonth!,
          mailIn: true,
        },
      });
      if (res) {
        props.handleClose();

        await props.refetchParticipants!();

        setSubmitting(false);

        toast.show({
          content: `${
            data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1)
          } Mail-in has been edited successfully.`,
          duration: 3000,
          error: false,
        });
      }
    } catch (e) {
      props.handleClose();

      setSubmitting(false);

      toast.show({
        content: `${
          data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1)
        }} Mail-in edit is failed.`,
        duration: 3000,
        error: true,
      });

      throw e;
    }
    setSubmitting(false);
  }

  return (
    <div
      className="modal fade warning"
      id="Modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={styles.modal}
    >
      {props.modalType === "MailInAddPopUp" && (
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ flexDirection: "row-reverse" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ flexDirection: "row-reverse", border: "none" }}
                onClick={() => props.handleClose()}
              >
                <span
                  aria-hidden="true"
                  style={{ fontWeight: "bold", backgroundColor: "#fff" }}
                >
                  X
                </span>
              </button>
            </div>

            <div className="modal-body">
              <h3 style={{ padding: "10px", marginRight: "5rem" }}>
                {props.header}
              </h3>
              <h5 style={{ textAlign: "left", width: "75%" }}>
                {props.message}
              </h5>{" "}
              <br />
              <div className="main__form form">
                <div className="form__body">
                  <form onSubmit={handleSubmit(handleAddUuids)}>
                    <div className="formContent">
                      <div className="form-group">
                        <label className="form__label">Draw month</label>
                        <select
                          className="form-control-select"
                          style={{ width: "50%" }}
                          {...register("drawMonth", {
                            required: {
                              value: true,
                              message: "Please select a draw month.",
                            },
                          })}
                        >
                          <option value="">Select</option>
                          <option value={`2024-02`}>
                            February 2024
                          </option>
                          <option value={`2024-03`}>
                            March 2024
                          </option>
                          <option value={`2024-04`}>
                            April 2024
                          </option>
                          <option value={`2024-05`}>
                            May 2024
                          </option>
                          <option value={`2024-06`}>
                            June 2024
                          </option>
                          <option value={`2024-07`}>
                            July 2024
                          </option>
                          <option value={`2024-08`}>
                            August 2024
                          </option>
                          <option value={`2024-09`}>
                            September 2024
                          </option>
                          <option value={`2024-10`}>
                            October 2024
                          </option>
                          <option value={`2024-11`}>
                            November 2024
                          </option>
                          <option value={`2024-12`}>
                            December 2024
                          </option>
                          <option value={`2025-01`}>
                            January 2025
                          </option>
                        </select>
                        {errors.drawMonth && (
                          <p className="error" style={{ width: "50%" }}>
                            <i className="fas fa-exclamation-circle" />{" "}
                            {errors.drawMonth.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="form__label">
                        Number of participants
                      </label>
                      <button
                        className="field"
                        style={{ width: "10%" }}
                        onClick={(e) => {
                          handleSubtract(e);
                        }}
                      >
                        -
                      </button>
                      <input
                        type="number"
                        className="field"
                        style={{
                          width: "15%",
                          paddingLeft: "15px",
                          textAlign: "center",
                          pointerEvents: "none",
                          WebkitAppearance: "none",
                        }}
                        value={props.number}
                        {...register("numberOfMailIns", {
                          required: {
                            value: true,
                            message: "Please select number of Mail-ins.",
                          },
                        })}
                      />
                      <button
                        className="field"
                        style={{ width: "10%" }}
                        onClick={(e) => {
                          handleAdd(e);
                        }}
                      >
                        +
                      </button>
                      {errors.numberOfMailIns && (
                        <p className="error" style={{ width: "50%" }}>
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.numberOfMailIns.message}
                        </p>
                      )}
                    </div>

                    <br />
                    <div className="modal-footer">
                      <>
                        <button
                          type="submit"
                          className="btn btn-primary small"
                          disabled={submitting}
                        >
                          {submitting ? "...Adding" : "Add"}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary small outline"
                          data-dismiss="modal"
                          onClick={(e) => {
                            handleClose(e);
                          }}
                        >
                          Cancel
                        </button>
                      </>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {props.modalType === "MailInEditPopUp" && (
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ flexDirection: "row-reverse" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ flexDirection: "row-reverse", border: "none" }}
                onClick={() => props.handleClose()}
              >
                <span
                  aria-hidden="true"
                  style={{ fontWeight: "bold", backgroundColor: "#fff" }}
                >
                  X
                </span>
              </button>
            </div>

            <div className="modal-body">
              <h3
                style={{ padding: "10px 10px 10px 0px", marginRight: "5rem" }}
              >
                {props.header}
              </h3>
              <h5 style={{ textAlign: "left", width: "75%" }}>
                {props.message}
              </h5>{" "}
              <br />
              <div className="main__body main__body--flex">
                <div className="main__form form" style={{ width: "100%" }}>
                  <div className="form__body">
                    <form onSubmit={handleSubmit(handleRegister)}>
                      <div className="formContent">
                        <div className="form-group">
                          <label className="form__label">First Name</label>
                          <input
                            {...register("firstName", {
                              required: {
                                value: true,
                                message: "Please enter your first name.",
                              },
                              pattern: {
                                value: stringRegex,
                                message: "Please enter a valid first name.",
                              },
                            })}
                            placeholder="First Name"
                            className="form-control"
                            type="text"
                          />
                          {errors.firstName && (
                            <p className="error">
                              <i className="fas fa-exclamation-circle" />{" "}
                              {errors.firstName.message}
                            </p>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="form__label">Last Name</label>
                          <input
                            {...register("lastName", {
                              required: {
                                value: true,
                                message: "Please enter your last name.",
                              },
                              pattern: {
                                value: stringRegex,
                                message: "Please enter a valid last name.",
                              },
                            })}
                            placeholder="Last Name"
                            className="form-control"
                            type="text"
                          />
                          {errors.lastName && (
                            <p className="error">
                              <i className="fas fa-exclamation-circle" />{" "}
                              {errors.lastName.message}
                            </p>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="form__label">Email</label>
                          <input
                            {...register("email", {
                              required: {
                                value: true,
                                message: "Please enter your email.",
                              },
                              pattern: {
                                value: emailRegex,
                                message: "Please enter a valid email.",
                              },
                            })}
                            placeholder="Email"
                            type="email"
                            className="form-control"
                          />
                          {errors.email && (
                            <p className="error">
                              <i className="fas fa-exclamation-circle" />{" "}
                              {errors.email.message}
                            </p>
                          )}{" "}
                        </div>

                        <div className="form-group">
                          <label className="form__label">Phone number</label>
                          <input
                            {...register("phone", {
                              required: {
                                value: true,
                                message: "Please enter your phone number.",
                              },
                              pattern: {
                                value: looseNorthAmericanPhoneRegex,
                                message: "Please enter a valid phone number.",
                              },
                            })}
                            placeholder="Phone number"
                            type="number"
                            className="form-control"
                          />
                          {errors.phone && (
                            <p className="error">
                              <i className="fas fa-exclamation-circle" />{" "}
                              {errors.phone.message}
                            </p>
                          )}
                        </div>

                        <div
                          className="modal-footer"
                          style={{ alignItems: "flex-start" }}
                        >
                          <button
                            type="button"
                            className="btn"
                            data-dismiss="modal"
                            style={{ backgroundColor: "" }}
                            onClick={(e) => {
                              handleClose(e);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn"
                            disabled={submitting}
                          >
                            {submitting ? "...Submitting" : "Submit"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {props.modalType === "submitModal" && (
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ flexDirection: "row-reverse" }}
        >
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ flexDirection: "row-reverse" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ flexDirection: "row-reverse", border: "none" }}
                onClick={() => props.handleClose()}
              >
                <span
                  aria-hidden="true"
                  style={{ fontWeight: "bold", backgroundColor: "#fff" }}
                >
                  X
                </span>
              </button>
            </div>
            <div className="modal-body">
              <h3
                style={{ padding: "10px 10px 10px 0px", marginRight: "5rem" }}
              >
                {props.header}
              </h3>
              <h5 style={{ textAlign: "left", width: "75%" }}>
                {props.message}
              </h5>{" "}
              <br />
              <div
                className="main__body main__body--flex"
                style={{ flexDirection: "row-reverse" }}
              >
                <div
                  className="form__footer"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <button className="btn" onClick={() => props.handleClose!()}>
                    Cancel
                  </button>
                  <button
                    className="btn"
                    style={{ marginLeft: "15px" }}
                    onClick={() => props.handleUpload!()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
