import { useNavigate } from "react-router-dom";
import { Row, TableProps } from "react-table";

export const TableColumns = () => {
  const navigate = useNavigate();
  const COLUMNS = [
    {
      Header: "No.",
      accessor: "colNumber",
      width: "3.6%",
      Cell: (TableInfo: TableProps) => {
        const nmb = TableInfo.row.index + 1;
        return nmb;
      },
    },
    {
      Header: "Winner month",
      accessor: "winnerMonth",
      width: "5.1%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].winnerMonth
          ? TableInfo.data[TableInfo.row.index].winnerMonth
          : "N/A";
      },
    },
    {
      Header: "Claim date/ time",
      accessor: "fulfillTime",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (rowA.values[columnId] > rowB.values[columnId]) return 1;
        if (rowB.values[columnId] > rowA.values[columnId]) return -1;
        return 0;
      },
      width: "9.3%",
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].fulfillTime
          ? TableInfo.data[TableInfo.row.index].fulfillTime.toLocaleString()
          : "";
        return dateString;
      },
    },
    {
      Header: "First name",
      accessor: "firstName",
      width: "7.7%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].firstName
          ? TableInfo.data[TableInfo.row.index].firstName
          : "N/A";
      },
    },
    {
      Header: "Last name",
      accessor: "lastName",
      width: "7.7%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].lastName
          ? TableInfo.data[TableInfo.row.index].lastName
          : "N/A";
      },
    },
    {
      Header: "Email",
      accessor: "email",
      width: "13.1%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].email ? (
          <a
            style={{ color: "blue" }}
            onClick={() =>
              navigate(
                `/winnerDetails/${
                  TableInfo.data[TableInfo.row.index].sessionKey
                }`
              )
            }
          >
            {TableInfo.data[TableInfo.row.index].email}
          </a>
        ) : (
          "N/A"
        );
      },
    },
  ];
  return COLUMNS;
};
